
export default class FZPlayerEvents {

    // event messages that player will send us

    static PLAYER_READY = "playerReady";
	// player is loaded and ready to accept commands
	// data = undefined
	/*	// callback function
		this.FZ_PlayerLoaded = function()
		{
			// player has been loaded and can accept commands
		}
	*/

	static HTML_PLAYER_READY = "htmlPlayerReady";
	// the zing data has been loaded from the server and playback is about to begin
	// use this to remove any covers, logos, etc
	// data = undefined
	/* // callback function
		this.FZ_PrepareForPlayback = function()
		{
			// playback is about to begin, remove any covers, logos, etc
		}
    */
    
	static SHOW_PAGE = "showPage";
	// a new page is about to be shown
	/* data = object, {
		 page: 1, // page number about to be shown
		 numPages: 3 // total number of pages in fabZing
		}
	*/
	/* // callback function
		this.FZ_ShowPage = function( page, numPages )
		{
			// a new page is about to be shown
		}
    */
    
   static PLAYER_PAUSED = "playerPaused";
   // player has been paused or resumed
   /* data = object {
       paused: true or false
    }
    */
    /* // callback function
       this.FZ_PlayerPaused = function( paused )
       {
           // playback has been paused or resumed,
           // if your interface has playback buttons, adjust them
       }
   */



	static ZING_FINISHED = "zingFinished";
	// zing has finished playback
	// data = undefined
	/* callback function
		this.FZ_PlaybackFinished = function()
		{
			// zing has finished playback
		}
	*/



	static MERGE_TAGS = "mergeTags";
	// player informing us of merge tags in current zing
	// sent in response to REQUEST_MERGE_TAGS
	// data = object with merge tags

	static MERGE_DATA = "mergeData";
	// player informing us of current merge data in current zing
	// sent in response to REQUEST_MERGE_DATA
	// data = object with merge data

		
	AUDIO_FINISHED = "audioFinished";
	// an audio file has finished playback
	// data = undefined
}

