import React from 'react';
import QuickZingPlayer from './QuickZingPlayer';
import FZPlayerEvents from './FZPlayerEvents';

import './index.css';

class App extends React.Component {

  receipt_url = "https://fst.fabtale.com/vrz/Ospreys-RECEIPT.jpg";

  constructor( props )
  {
    super( props );
    this.state = { showReceipt: false, playerPaused: false }
  }

  // events from quickzing player, only interested in some of them
  playerEvent = ( event, data ) => {

    switch( event )
    {
      case FZPlayerEvents.SHOW_PAGE:

        if ( ! this.state.showReceipt )
        {
          this.setState( { showReceipt: true } );
        }
        break;

      case FZPlayerEvents.PLAYER_PAUSED:

        this.setState( { playerPaused: data.paused } );
        break;
        
      default: break;
    }
  }

  render () {


    return (
      <div className="app">
        <div className="player_holder">
          <QuickZingPlayer onPlayerEvent={this.playerEvent} />
        </div>
      </div>
      );
    }
}

export default App;
